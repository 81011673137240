.container {
    width: 100%;
    max-width: 1180px;
    padding: 0 30px;
    margin: 32px auto;
}

.container h1 {
    margin-top: 50px;
    font-size: 2rem;
    color: #fff;
    margin-bottom: 10px;
}

.container h3 {
    margin-top: 20px;
    font-size: 1.5rem;
    color: #fff;
    margin-bottom: 10px;
}


.time-section {
    display: flex;
    justify-content: center;
}

.time {
    font-size: 5rem;
    color: #fff;
}

.projects {
    display: flex;
}

.projects-section {
    width: 100%;
    background: #fff;
    padding: 32px;
    margin: 10px;
    overflow-y: scroll;
}

.projects-section h2 {
    font-size: 2rem;
    color: #000;
    margin-bottom: 10px;
}

.projects-section .project-item {
    height: 50px;
    background: #d9dadd;
    display: flex;
    align-items: center;
    border-radius: 8px;
    margin: 10px 0;
    cursor: pointer;
}

.projects-section .project-item p {
    margin-left: 20px;
    font-size: 1rem;
    color: #000;
}

.actions {
    display: flex;
    justify-content: space-between;
}

.entrada {
    width: 33%;
    background: #4bae50;
    border: 0;
    color: #fff;
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
    line-height: 60px;
    margin-top: 16px;
    text-align: center;
    text-decoration: none;
}

.saida {
    width: 33%;
    background: red;
    border: 0;
    color: #fff;
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
    line-height: 60px;
    margin-top: 16px;
    text-align: center;
    text-decoration: none;
}

.reentrada {
    width: 33%;
    background: darkgoldenrod;
    border: 0;
    color: #fff;
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
    line-height: 60px;
    margin-top: 16px;
    text-align: center;
    text-decoration: none;
}


button:disabled {
    opacity: 0.4;
    cursor: not-allowed;
}

.active_project {
    /* font-weight: bold; */
    background: red !important;
    color: #fff !important;
}

.active_project p {
    color: #fff !important;
}

.active_flow {
    background: red !important;
    color: #fff !important;
}

.active_flow p {
    color: #fff !important;
}